@font-face {
  font-family: Inconsolata;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Inconsolata Regular), local(Inconsolata-Regular), url("inconsolata-latin-400.9c37f0e9.woff2") format("woff2"), url("inconsolata-latin-400.6a171001.woff") format("woff");
}

@font-face {
  font-family: Inconsolata;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Inconsolata Bold), local(Inconsolata-Bold), url("inconsolata-latin-700.4fdafc85.woff2") format("woff2"), url("inconsolata-latin-700.b2195485.woff") format("woff");
}
/*# sourceMappingURL=index.0af2782e.css.map */
